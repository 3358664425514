import {Injectable} from '@angular/core';
import {Observable, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AlertsHeaderService {
  private reloadAlerts$: Subject<void> = new Subject<void>()

  constructor() {
  }

  triggerAlertsReload(): void {
    this.reloadAlerts$.next()
  }

  onAlertsReload(): Observable<void> {
    return this.reloadAlerts$.asObservable()
  }
}
